*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body, #root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, input, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

._7s0bRq_selectedRow {
  color: #fff !important;
  background-color: #2884fc !important;
}

._7s0bRq_songTableRow {
  cursor: pointer;
}

._7s0bRq_starsTD {
  width: 20px;
}

._7s0bRq_invisiblePlaceholder {
  visibility: hidden;
}

._7s0bRq_invisibleImage {
  visibility: hidden;
  display: none;
}

._7s0bRq_speakerIcon {
  width: 15px;
  height: 15px;
  margin-left: -16px;
}

._7s0bRq_speakerOnUnselectedIcon {
  width: 15px;
  height: 15px;
  margin-top: -1px;
  margin-left: -16px;
}

._7s0bRq_relativePositionClass {
  position: relative;
}

._7s0bRq_absolutePositionClass {
  position: absolute;
  top: 0;
  left: 0;
}

.COgzLa_star {
  color: var(--star-default-color);
}

.COgzLa_starHighlighted {
  color: var(--star-selected-color);
}

.COgzLa_unstar {
  color: var(--star-nonstar-outline);
}

.czP_4q_modal {
  z-index: 1000;
  background-color: #0006;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  font-family: Lato, sans-serif;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.czP_4q_modalContent {
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 4px;
  width: 80%;
  max-width: 500px;
  margin-top: 10%;
  padding: 20px;
  box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030;
}

.czP_4q_modalContent h2 {
  margin-bottom: 20px;
}

.czP_4q_modalContent div {
  margin-bottom: 10px;
}

.czP_4q_modalContent input {
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #888;
  width: 100%;
  margin: 8px 0;
  padding: 12px 20px;
}

.czP_4q_modalContent button {
  color: #fff;
  cursor: pointer;
  opacity: .9;
  background-color: #1f5cab;
  border: none;
  width: 100%;
  margin: 8px 0;
  padding: 14px 20px;
}

.czP_4q_modalContent button:hover {
  opacity: 1;
}

td {
  overflow: hidden;
}

._12cQpG_sticky {
  height: 100%;
}

.BOBUQq_stickyFooter {
  background-image: linear-gradient(var(--footer-gradient-top), var(--footer-gradient-bottom));
  text-align: center;
  border: 1px solid #aaa;
  width: 100%;
  padding: 2px;
  font-size: 12px;
  position: fixed;
  bottom: 0;
  font-family: Lato, sans-serif !important;
}

.WquMza_button {
  cursor: pointer;
}

.WquMza_artist {
  transform: translate(0, -3.5px);
}

.WquMza_playButton {
  width: 22px;
  height: 25px;
}

.WquMza_backButton {
  width: 39px;
  height: 25px;
  margin-left: 38px;
}

.WquMza_forwardButton {
  width: 39px;
  height: 25px;
}

.WquMza_shuffleDefault {
  width: 20px;
  height: 14px;
  margin-top: 4px;
}

.WquMza_loopButton {
  width: 26px;
  height: 19px;
  margin-top: 2px;
}

.WquMza_loopOnButton {
  width: 26px;
  height: 16px;
  margin-top: 2px;
}

.WquMza_topRow {
  border: var(--audio-player-border);
}

.WquMza_outerLeft {
  background-image: linear-gradient(var(--player-gradient-top), var(--player-gradient-bottom));
  width: 12.5%;
}

.WquMza_volumeCell {
  border-right: var(--audio-player-border);
  background-image: linear-gradient(var(--player-gradient-top), var(--player-gradient-bottom));
  width: 17.5%;
}

.WquMza_outerRight {
  border-left: var(--audio-player-border);
  background-image: linear-gradient(var(--player-gradient-top), var(--player-gradient-bottom));
  width: 30%;
}

.WquMza_songTimer {
  background-color: var(--player-inner-cell-background);
  width: 30px;
  font-size: 10px;
  font-weight: 400;
  position: relative;
}

.WquMza_songName {
  color: #000;
  margin-top: 6px;
  font-size: 13px;
  font-weight: 500;
}

.WquMza_middleCell {
  background-color: var(--player-inner-cell-background);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.WquMza_timeContainer {
  justify-content: space-between;
  width: 100%;
  font-size: small;
  display: flex;
}

.WquMza_iconUpperLeft {
  padding-left: 5px;
  font-size: 11px;
  position: absolute;
  top: 0;
}

.WquMza_timeLowerLeft {
  padding-left: 5px;
  position: absolute;
  bottom: 0;
}

.WquMza_iconUpperRight {
  font-size: 11px;
  position: absolute;
  top: 0;
}

.WquMza_timeLowerRight {
  position: absolute;
  bottom: 0;
}

.WquMza_playerUI {
  background-color: var(--player-inner-cell-background);
  text-align: center;
  align-items: center;
  width: 100%;
  margin-top: -8px;
  position: relative;
}

.WquMza_infoAndTimes {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -10px;
  display: flex;
}

.WquMza_timeSpan {
  font-family: Lato, sans-serif;
}

.WquMza_playbackSpan {
  color: inherit;
}

.WquMza_durationSpan {
  float: right;
}

.WquMza_invisiblePlaceholder {
  visibility: hidden;
}

.WquMza_invisibleImage {
  visibility: hidden;
  display: none;
}

.WquMza_relativePositionClass {
  position: relative;
}

.WquMza_absolutePositionClass {
  position: absolute;
  top: 0;
  left: 0;
}

.WquMza_slider {
  --handle-size: 16px;
  --handle-height: 10px;
  --handle-width: 3px;
  --handle-radius: 10px;
  --track-size: 5px;
  --bg: white;
  --track-color: #54545431;
  --handle-color: #222;
  --track-active-color: #555;
  --handle-active-color: #1f00b8;
  appearance: none;
  outline-offset: 6px;
  cursor: pointer;
  background: linear-gradient(to right, #555 0% 50%, #54545431 50%);
  width: 100%;
  margin-top: 10px;
  display: block;
}

.WquMza_slider::-webkit-slider-thumb {
  appearance: none;
  height: var(--handle-height);
  width: var(--handle-width);
  border-radius: var(--handle-radius);
  background: var(--handle-color);
  cursor: grab;
  transform: translateY(calc(-50% + var(--track-size) / 2));
  outline: 1px solid var(--bg);
}

.WquMza_slider::-moz-range-thumb {
  background: var(--handle-color);
  border: 2px solid var(--bg);
  border-radius: var(--handle-radius);
  height: var(--handle-size);
  width: var(--handle-size);
}

.WquMza_slider:active::-webkit-slider-thumb {
  cursor: grabbing;
  background: var(--handle-active-color);
}

.WquMza_slider:active::-moz-range-thumb {
  cursor: grabbing;
  background: var(--handle-active-color);
}

.WquMza_slider::-webkit-slider-runnable-track {
  width: calc(100% - var(--handle-size));
  height: var(--track-size);
  background: var(--track-color);
  border-radius: var(--track-size) / 2;
}

.WquMza_slider::-moz-range-track {
  background: var(--track-color);
}

.WquMza_slider:active::-webkit-slider-runnable-track {
  background: var(--track-active-color);
}

.WquMza_slider:active::-moz-range-track {
  background: var(--track-active-color);
}

.WquMza_slider:before {
  -webkit-appearance: none;
  content: "";
  width: var(--seek-before-width);
  color: #ff0;
  cursor: pointer;
  background: #e5ff00;
  height: 3px;
  position: absolute;
  top: 8px;
  left: 0;
}

.cTdk1a_searchBar {
  background-color: #fff;
  background-image: url("magnifying-glass.63246dc2.png");
  background-position: 0;
  background-repeat: no-repeat;
  border-radius: 4px;
  width: 40%;
  height: 22px;
  padding-left: 30px;
  border: 1.5px solid #aaa !important;
  margin-left: 55% !important;
}

.cTdk1a_searchBar:focus {
  color: #000;
  background-color: #fff;
  outline-color: #8091d9;
  box-shadow: 0 0 20px #8091d9;
  border: 2px solid #8091d9 !important;
}

.SliderRoot {
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  align-items: center;
  width: clamp(10px, 9vw, 100px);
  height: 20px;
  display: flex;
  position: relative;
  translate: 0 -2px;
}

.SliderTrack {
  background-color: #b8b8b8;
  border-radius: .5rem;
  flex-grow: 1;
  height: 5px;
  position: relative;
}

.SliderRange {
  background-color: #707070;
  border-radius: 9999px;
  height: 100%;
  position: absolute;
}

.SliderThumb {
  box-shadow: 0 1px 2px var(--black-a7);
  background-color: #f2f2f2;
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  width: 14px;
  height: 14px;
  display: block;
}

:root {
  --black-a1: #0000000d;
  --black-a2: #0000001a;
  --black-a3: #00000026;
  --black-a4: #0003;
  --black-a5: #0000004d;
  --black-a6: #0006;
  --black-a7: #00000080;
  --black-a8: #0009;
  --black-a9: #000000b3;
  --black-a10: #000c;
  --black-a11: #000000e6;
  --black-a12: #000000f2;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root {
      --black-a1: #0000000d;
      --black-a2: #0000001a;
      --black-a3: #00000026;
      --black-a4: #0003;
      --black-a5: #0000004d;
      --black-a6: #0006;
      --black-a7: #00000080;
      --black-a8: #0009;
      --black-a9: #000000b3;
      --black-a10: #000c;
      --black-a11: #000000e6;
      --black-a12: #000000f2;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root {
        --black-a1: color(display-p3 0 0 0 / .05);
        --black-a2: color(display-p3 0 0 0 / .1);
        --black-a3: color(display-p3 0 0 0 / .15);
        --black-a4: color(display-p3 0 0 0 / .2);
        --black-a5: color(display-p3 0 0 0 / .3);
        --black-a6: color(display-p3 0 0 0 / .4);
        --black-a7: color(display-p3 0 0 0 / .5);
        --black-a8: color(display-p3 0 0 0 / .6);
        --black-a9: color(display-p3 0 0 0 / .7);
        --black-a10: color(display-p3 0 0 0 / .8);
        --black-a11: color(display-p3 0 0 0 / .9);
        --black-a12: color(display-p3 0 0 0 / .95);
      }
    }
  }
}

:root, .light, .light-theme {
  --mauve-1: #fdfcfd;
  --mauve-2: #faf9fb;
  --mauve-3: #f2eff3;
  --mauve-4: #eae7ec;
  --mauve-5: #e3dfe6;
  --mauve-6: #dbd8e0;
  --mauve-7: #d0cdd7;
  --mauve-8: #bcbac7;
  --mauve-9: #8e8c99;
  --mauve-10: #84828e;
  --mauve-11: #65636d;
  --mauve-12: #211f26;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --mauve-1: #fdfcfd;
      --mauve-2: #faf9fb;
      --mauve-3: #f2eff3;
      --mauve-4: #eae7ec;
      --mauve-5: #e2dfe6;
      --mauve-6: #dbd8e0;
      --mauve-7: #d0cdd7;
      --mauve-8: #bcbac7;
      --mauve-9: #8e8c99;
      --mauve-10: #83818e;
      --mauve-11: #65636d;
      --mauve-12: #211f26;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --mauve-1: color(display-p3 .991 .988 .992);
        --mauve-2: color(display-p3 .98 .976 .984);
        --mauve-3: color(display-p3 .946 .938 .952);
        --mauve-4: color(display-p3 .915 .906 .925);
        --mauve-5: color(display-p3 .886 .876 .901);
        --mauve-6: color(display-p3 .856 .846 .875);
        --mauve-7: color(display-p3 .814 .804 .84);
        --mauve-8: color(display-p3 .735 .728 .777);
        --mauve-9: color(display-p3 .555 .549 .596);
        --mauve-10: color(display-p3 .514 .508 .552);
        --mauve-11: color(display-p3 .395 .388 .424);
        --mauve-12: color(display-p3 .128 .122 .147);
      }
    }
  }
}

:root, .light, .light-theme {
  --violet-1: #fdfcfe;
  --violet-2: #faf8ff;
  --violet-3: #f4f0fe;
  --violet-4: #ebe4ff;
  --violet-5: #e1d9ff;
  --violet-6: #d4cafe;
  --violet-7: #c2b5f5;
  --violet-8: #aa99ec;
  --violet-9: #6e56cf;
  --violet-10: #654dc4;
  --violet-11: #6550b9;
  --violet-12: #2f265f;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --violet-1: #fdfcfe;
      --violet-2: #faf8ff;
      --violet-3: #f4f0fe;
      --violet-4: #ebe5ff;
      --violet-5: #e1d9ff;
      --violet-6: #d4cafe;
      --violet-7: #c2b5f5;
      --violet-8: #aa99ec;
      --violet-9: #6e56cf;
      --violet-10: #654dc4;
      --violet-11: #6550b9;
      --violet-12: #2f265f;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --violet-1: color(display-p3 .991 .988 .995);
        --violet-2: color(display-p3 .978 .974 .998);
        --violet-3: color(display-p3 .953 .943 .993);
        --violet-4: color(display-p3 .916 .897 1);
        --violet-5: color(display-p3 .876 .851 1);
        --violet-6: color(display-p3 .825 .793 .981);
        --violet-7: color(display-p3 .752 .712 .943);
        --violet-8: color(display-p3 .654 .602 .902);
        --violet-9: color(display-p3 .417 .341 .784);
        --violet-10: color(display-p3 .381 .306 .741);
        --violet-11: color(display-p3 .383 .317 .702);
        --violet-12: color(display-p3 .179 .15 .359);
      }
    }
  }
}

.ContextMenu {
  font-size: 30px;
}

.ContextMenuContent, .ContextMenuSubContent {
  background-color: #fff;
  border: 1.5px solid #ddd;
  border-radius: 2px;
  min-width: 200px;
  padding: 5px;
  overflow: hidden;
  box-shadow: 0 10px 38px -10px #16171859, 0 10px 20px -15px #16171833;
}

.ContextMenuItem, .ContextMenuCheckboxItem, .ContextMenuRadioItem, .ContextMenuSubTrigger {
  color: #000;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  outline: none;
  align-items: center;
  height: 20px;
  padding: 0 5px 0 15px;
  font-family: Lato, sans-serif;
  font-size: 12px;
  line-height: 1;
  display: flex;
  position: relative;
}

.ContextMenuSubTrigger[data-state="open"] {
  color: #000;
  background-color: #eee;
}

.ContextMenuItem[data-disabled], .ContextMenuCheckboxItem[data-disabled], .ContextMenuRadioItem[data-disabled], .ContextMenuSubTrigger[data-disabled] {
  color: var(--mauve-8);
  pointer-events: "none";
}

.ContextMenuItem[data-highlighted], .ContextMenuCheckboxItem[data-highlighted], .ContextMenuRadioItem[data-highlighted], .ContextMenuSubTrigger[data-highlighted] {
  color: #fff;
  background-color: #296dff;
}

.ContextMenuLabel {
  color: var(--mauve-11);
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
}

.ContextMenuSeparator {
  background-color: #ddd;
  height: .8px;
  margin: 5px;
}

.ContextMenuItemIndicator {
  justify-content: center;
  align-items: center;
  width: 25px;
  display: inline-flex;
  position: absolute;
  left: 0;
}

.RightSlot {
  color: var(--mauve-11);
  margin-left: auto;
  padding-left: 20px;
}

[data-highlighted] > .RightSlot {
  color: #fff;
}

[data-disabled] .RightSlot {
  color: var(--mauve-8);
}

button, fieldset, input {
  all: unset;
}

.AuthDialogOverlay {
  background-color: var(--black-a9);
  animation: .15s cubic-bezier(.16, 1, .3, 1) overlayShow;
  position: fixed;
  inset: 0;
}

.AuthDialogContent {
  background-color: #fff;
  border-radius: 6px;
  width: 90vw;
  max-width: 450px;
  animation: .15s cubic-bezier(.16, 1, .3, 1) contentShow;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 10px 38px -10px #0e121659, 0 10px 20px -15px #0e121633;
}

.AuthDialogTitle {
  color: var(--mauve-12);
  margin: 0;
  font-weight: 500;
}

.TabsRoot {
  box-shadow: 0 2px 10px var(--black-a4);
  flex-direction: column;
  width: 300px;
  display: flex;
}

.TabsList {
  border-bottom: 1px solid var(--mauve-6);
  flex-shrink: 0;
  display: flex;
}

.TabsTrigger {
  color: var(--mauve-11);
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 45px;
  padding: 0 20px;
  font-family: inherit;
  font-size: 15px;
  line-height: 1;
  display: flex;
}

.TabsTrigger:first-child {
  border-top-left-radius: 6px;
}

.TabsTrigger:last-child {
  border-top-right-radius: 6px;
}

.TabsTrigger:hover {
  color: var(--violet-11);
}

.TabsTrigger[data-state="active"] {
  color: var(--violet-11);
  box-shadow: inset 0 -1px, 0 1px;
}

.TabsTrigger:focus {
  position: relative;
}

.TabsContent {
  background-color: #fff;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  outline: none;
  flex-grow: 1;
  padding: 20px;
}

.Text {
  color: var(--mauve-11);
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 1.5;
}

.Fieldset {
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
}

.Label {
  color: var(--violet-12);
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 1;
  display: block;
}

.Input {
  color: var(--violet-11);
  box-shadow: 0 0 0 1px var(--violet-7);
  border-radius: 4px;
  flex: 1 0 auto;
  height: 35px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
}

.Input:focus {
  box-shadow: 0 0 0 2px var(--violet-8);
}

.Button {
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  display: inline-flex;
}

.Button.green {
  background-color: var(--green-4);
  color: var(--green-11);
}

.Button.green:hover {
  background-color: var(--green-5);
}

.Button.green:focus {
  box-shadow: 0 0 0 2px var(--green-7);
}

/*# sourceMappingURL=index.5903eec6.css.map */
