@import '/node_modules/@radix-ui/colors/black-alpha.css';
@import '/node_modules/@radix-ui/colors/mauve.css';
@import '/node_modules/@radix-ui/colors/violet.css';

.ContextMenu {
  font-size: 30px;
}

.ContextMenuContent,
.ContextMenuSubContent {
  /* min-width: fit-content; */
  min-width: 200px;
  background-color: white;
  border-radius: 2px;
  overflow: hidden;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  border: 1.5px solid #ddd;
}

.ContextMenuItem,
.ContextMenuCheckboxItem,
.ContextMenuRadioItem,
.ContextMenuSubTrigger {
  font-size: 12px;
  line-height: 1;
  font-family: 'Lato', sans-serif;
  color: black;
  /* color: var(--violet-11); */
  border-radius: 3px;
  display: flex;
  align-items: center;
  /* height: 25px; */
  height: 20px;
  padding: 0 5px;
  position: relative;
  padding-left: 15px;
  user-select: none;
  outline: none;
}
.ContextMenuSubTrigger[data-state='open'] {
  /* background-color: var(--violet-4); */
  /* color: var(--violet-11); */
  background-color: #eee;
  color: black;
}
.ContextMenuItem[data-disabled],
.ContextMenuCheckboxItem[data-disabled],
.ContextMenuRadioItem[data-disabled],
.ContextMenuSubTrigger[data-disabled] {
  color: var(--mauve-8);
  pointer-events: 'none';
}
.ContextMenuItem[data-highlighted],
.ContextMenuCheckboxItem[data-highlighted],
.ContextMenuRadioItem[data-highlighted],
.ContextMenuSubTrigger[data-highlighted] {
  /* background-color: var(--violet-9); */
  /* color: var(--violet-1); */
  background-color: hsl(221, 100%, 58%);
  color: white;
}

.ContextMenuLabel {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.ContextMenuSeparator {
  height: 0.8px;
  background-color: #ddd;
  /* background-color: var(--violet-6); */
  margin: 5px;
}

.ContextMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: var(--mauve-11);
}
[data-highlighted] > .RightSlot {
  color: white;
}
[data-disabled] .RightSlot {
  color: var(--mauve-8);
}
