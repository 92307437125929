.SliderRoot {
  translate: 0 -2px;
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: clamp(10px, 9vw, 100px);
  height: 20px;
}

.SliderTrack {
  background-color: hsl(0, 0%, 72%);
  position: relative;
  flex-grow: 1;
  border-radius: 0.5rem;
  height: 5px;
}

.SliderRange {
  position: absolute;
  background-color: hsl(0, 0%, 44%);
  border-radius: 9999px;
  height: 100%;
}

.SliderThumb {
  display: block;
  width: 14px;
  height: 14px;
  background-color: hsl(0, 0%, 95%);
  box-shadow: 0 1px 2px var(--black-a7);
  border-radius: 10px;
  border: 1px solid hsl(0, 0%, 76%);
}
/* .SliderThumb:hover {
  background-color: var(--violet-3);
} */
/* .SliderThumb:focus {
  outline: none;
  box-shadow: 0 0 0 5px var(--black-a8);
} */
